<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="供货商" >
                <el-input v-model="search.gongHuoShang" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="table.data" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="预付款导出">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column width="60" label="序号" type="index" fixed/>
            <el-table-column width="200" prop="gongHuoShang" label="供货商" />
            <el-table-column width="200" prop="lianXiRen" label="联系人" />
            <el-table-column width="250" prop="shouJiHao" label="手机号" />
            <el-table-column width="300" prop="lianXiDZ" label="联系地址" />
            <el-table-column width="200" prop="yuFuKuanJinE" label="预付款金额" />
            <el-table-column width="300" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleJiLu(row.id)">记录</el-button>
                    <el-button type="danger" size="mini" round  @click="handleUpdate(row)" icon="el-icon-coin">预付款操作</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <ji-lu-list ref="jiLuList"></ji-lu-list>

    </div>
</template>
<script>
    import * as service from "@/service/zjgl/YuFuKuan";
    import * as yuFuKuanCaoZuoService from "@/service/zjgl/YuFuKuanCaoZuoJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/zjgl/yfkgl/YuFuKuanCaoZuoJiLuEdit";
    import {DICT_TYPE_YFK_OPERATION} from "@/util/constant";
    import JiLuList from "@/view/zjgl/yfkgl/YuFuKuanCaoZuoJiLuList"
    import {ruleBuilder} from '@/util/validate';

    export default {
        name: "YuFuKuanList",
        mixins: [XTableBase],
        components: {Edit,JiLuList},
        data() {
            let {required,digits,positiveEightTwo} = ruleBuilder;
            this.refreshService = service.list;
            this.service = service;
            return {
                rules:{
                    jinE: [required()],
                },
                czWay:DICT_TYPE_YFK_OPERATION,
                search: {
                    gongHuoShang: '',
                },
                headAndKey: {
                    gongHuoShang: "供货商",
                    lianXiRen: "联系人",
                    shouJiHao: "手机号",
                    lianXiDZ: "联系地址",
                    yuFuKuanJinE: "预付款金额",
                },
                condition: [],
            }
        },
        methods:{
            handleJiLu(id){
                this.$refs.jiLuList.jiLuDisabled = true
                this.$refs.jiLuList.search.parentId = id
                this.$nextTick(()=>{
                    this.$refs.jiLuList.show();
                })
            },
            handleUpdate(row){
                this.editor.isUpdate = false;
                this.editor.id = null;
                this.editor.visible = true;
                this.$refs.edit.yuFuKuanObj = row
            },
        }
    }
</script>

<style scoped>
</style>
