<template>
    <el-dialog :title="shiJiTitle" :visible.sync="jiLuDisabled"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
            <!-- 列表区域 -->
            <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                      @selection-change="handleSelectionChange">
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column prop="gongHuoShang" label="供货商"/>
                <el-table-column prop="caoZuoRen" label="操作人"/>
                <el-table-column prop="leiXing" label="类型">
                    <x-dict-show slot-scope="{row}" :code="row.leiXing" dictType="T_YFK_OPERATION"/>
                </el-table-column>
                <el-table-column prop="jinE" label="金额"/>
                <el-table-column prop="caoZuoSJ" label="操作时间"/>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
    </el-dialog>
</template>
<script>
    import * as service from "@/service/zjgl/YuFuKuanCaoZuoJiLu";
    import XTableBase from "@/components/x/XTableBase";

    export default {
        name: "YuFuKuanCaoZuoJiLuList",
        mixins: [XTableBase],
        components: {},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    parentId: ''
                },
                jiLuDisabled:false,
                shiJiTitle:"记录"
            }
        },
        methods:{
            show(){
                this.refresh()
            }
        }
    }
</script>

<style scoped>
</style>
