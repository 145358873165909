<template>
    <el-dialog :title="shiJiTitle" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="类型" prop="leiXing" >
                <x-selector-one v-model="form.leiXing" dictType="T_YFK_OPERATION"/>
            </el-form-item>
            <el-form-item label="金额" prop="jinE" >
                <el-input v-model="form.jinE" size="small" type="number" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add} from "@/service/zjgl/YuFuKuanCaoZuoJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {deepCopy, deepMerge} from "@/util/objects"
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        components: {},
        beforeCreate() {
            let {required,positiveEightTwo} = ruleBuilder;
            this.rules = {
                gongHuoShang: [], // 供货商
                caoZuoRen: [], // 操作人
                leiXing: [required('string', ['blur', 'change'])], // 类型// 操作时间
                jinE: [required('string', ['blur', 'change']),positiveEightTwo()], // 金额
            }
            this.titlePrefix = '预付款操作记录';
            this.defaultForm = {
                id: null,
                parentId:'',
                gongHuoShang: "", // 供货商
                caoZuoRen: this.$store.getters.user.name, // 操作人
                leiXing: "", // 类型
                caoZuoSJ: "", // 操作时间
                jinE: "0.00", // 金额
            };
            this.addService = add;
        },
        data(){
            return{
                yuFuKuanObj:{},
                shiJiTitle:"预付款"
            }
        },
        methods: {
            open() {
                this.form = deepCopy(this.defaultForm);
                this.form.gongHuoShang = this.yuFuKuanObj.gongHuoShang
                this.form.parentId = this.yuFuKuanObj.id
                //清空校验
                this._clearValidate();
                return Promise.resolve({code: 200});
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let api =this.addService;
                const response = await api(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        }
    }
</script>

<style scoped>

</style>
