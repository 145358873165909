import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/yu-fu-kuan/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/yu-fu-kuan/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/yu-fu-kuan/get-update/${id}`,
        method: 'get',
    })
}

//获取编辑页数据
export function getUpdategetUpdateByGhsMc(gongHuoShangMC) {
    return request({
        url: `/yu-fu-kuan/get-update/${gongHuoShangMC}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/yu-fu-kuan/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/yu-fu-kuan/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/yu-fu-kuan/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/yu-fu-kuan/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'yu-fu-kuan-list',
    component: () => import('@/view/zjgl/yfkgl/YuFuKuanList'),
    name: 'YuFuKuanList',
    meta: {title: '预付款', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"预付款基础列表","methodUrl":"/yu-fu-kuan/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"预付款添加保存","methodUrl":"/yu-fu-kuan/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"预付款获取编辑数据","methodUrl":"/yu-fu-kuan/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"预付款获取详情数据","methodUrl":"/yu-fu-kuan/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"预付款编辑保存","methodUrl":"/yu-fu-kuan/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"预付款删除","methodUrl":"/yu-fu-kuan/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"预付款批量删除","methodUrl":"/yu-fu-kuan/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
